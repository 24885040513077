import React, { useEffect, useRef, useState } from 'react';

import playButton from '../img/play_button.png';
import '../style/custom-style.sass';

const VideoPlayer = ({ videoSrc, thumbnailSrc }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // The video autoplay is not respected when navigating using Gatsby Link component
  // So we just pause it on render
  useEffect(() => {
    videoRef.current.pause(); // Pause the video playback
  }, []);

  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };

  const VideoThumbnail = () => {
    return (
      <div
        className='video-overlay'
        style={{ background: `url(${thumbnailSrc})`, backgroundSize: 'cover' }}
      >
        <img
          className='play-button'
          src={playButton}
          alt='Play button'
          onClick={handlePlay}
        />
      </div>
    );
  };

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <video
        ref={videoRef}
        id='promo-video'
        controls={isPlaying}
        autoPlay={false}
        width='100%'
        onEnded={() => setIsPlaying(false)}
      >
        <source src={videoSrc} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      {
        !isPlaying && VideoThumbnail()
      }
    </div>
  );
};

export default VideoPlayer;
